import React from 'react';
import styled from 'styled-components';

const VideoPreview = styled.div`
  margin-top: 5rem;
  height: 50vh;
  width: 80%;
  align-self: center;
  border-radius: 15px;
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 100%;
    height: 30vh;
  }
`;
interface VideoProps {
  videoSrcURL: string;
  videoTitle: string;
}

const Video: React.FunctionComponent<VideoProps> = ({
  videoSrcURL,
  videoTitle,
}) => (
  <VideoPreview>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      width="100%"
      height="100%"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      allowFullScreen
    />
  </VideoPreview>
);
export default Video;
